import { analytics } from './firebase';

/**
 * @description
 * If you want to add a new event, copy&paste this function overload block, and refine its name and payload.
 * How does this work? ref: [TS function overload](https://www.typescriptlang.org/docs/handbook/functions.html#overloads)
 *
 * @example
 * ```
 * function logEvent(
 *   name: 'event-example',
 *   payload: {
 *    p11: string;
 *    p12: string;
 *    p13: string;
 *  }
 * ): void;
 * ```
 */

function logEvent(
  name: 'recipe_included',
  payload: {
    included_by: 'menu_blog' | 'campaign';
  }
): void;

function logEvent(
  name: 'SignInUp_entry',
  payload: {
    signinup_entry_name: string;
  }
): void;

function logEvent(
  name: 'SignInUp_select',
  payload: {
    signinup_selection: string;
  }
): void;

function logEvent(
  name: 'SignInUp_success',
  payload: {
    signinup_result: string;
    signinup_selection: string;
  }
): void;

function logEvent(
  name: 'FPv2_click',
  payload: {
    FPv2_module: string;
    FPv2_storytype: string;
    FPv2_content_in_module: string;
    FPv2_position_in_module: string;
  }
): void;

function logEvent(
  name: 'vip_entry_click',
  payload: {
    vip_entry_page: string;
    vip_entry_name: string;
    cta_text: string;
  }
): void;

function logEvent(
  name: 'recipe_ingredient_click',
  payload: {
    ingredient_name: string;
  }
): void;

function logEvent(
  name: 'lightbox_image_click',
  payload: {
    name: string;
    position: string;
  }
): void;

function logEvent(
  name: 'choice_recipe',
  payload: {
    destination_recipe: string;
    list: string;
    rank?: string;
  }
): void;

function logEvent(
  name: 'choice_category',
  payload: {
    destination_category: string;
  }
): void;

function logEvent(
  name: 'recipes_list_children',
  payload: {
    destination_child: string;
  }
): void;

function logEvent(
  name: 'v2_recipe_save_succeed',
  payload: {
    save_via: 'recipe_card' | 'single_recipe';
  }
): void;

function logEvent(
  name: 'c2_recipe_save',
  payload: {
    position: 'top' | 'after_tips' | 'fix_bottom_bar' | 'invitation';
  }
): void;

function logEvent(
  name: 'c2_recipe_w_invitation',
  payload: {
    click_area: 'skip' | 'save_now';
  }
): void;

function logEvent(name: 'c2_recipe_save_option_done', payload: {}): void;

function logEvent(
  name: 'c2_recipe_like',
  payload: {
    position: 'top' | 'after_tips' | 'fix_bottom_bar';
  }
): void;

function logEvent(
  name: 'c2_recipe_author_follow',
  payload: {
    position: 'top' | 'bottom';
  }
): void;

function logEvent(
  name: 'c2_author_follow',
  payload: {
    username: string;
    activated: boolean;
  }
): void;

function logEvent(
  name: 'c2_recipe_share',
  payload: {
    share_via: string;
    content_id: string;
  }
): void;

function logEvent(
  name: 'c2_dish_share',
  payload: {
    share_via: string;
    content_id: string;
  }
): void;

function logEvent(
  name: 'c2_list_share',
  payload: {
    share_via: string;
    creator: boolean;
    content_id: string;
  }
): void;

function logEvent(
  name: 'c2_campaign_share',
  payload: {
    share_via: string;
    content_id: string;
  }
): void;

function logEvent(
  name: 'v2_recipe_share_install',
  payload: {
    banner: 'share_install_v1-2' | 'share_install_v1-3';
    recipe_id: number;
  }
): void;

function logEvent(
  name: 'v2_recipe_share',
  payload: {
    experiment: 'control';
    content_id: string;
  }
): void;

function logEvent(
  name: 'v2_list_share_install',
  payload: {
    list_id: number;
    banner: 'list_share_install_v1-0' | 'list_share_install_v1-1';
  }
): void;

function logEvent(
  name: 'v2_shared_list',
  payload: {
    list_id: number;
  }
): void;

function logEvent(
  name: 'c2_search_submit',
  payload: {
    recipe_term: string;
    ingredient_term: string;
  }
): void;

function logEvent(
  name: 'c2_search_cooktime_filter',
  payload: {
    cooktime: string;
    keyword: string;
    resultCount: string;
    enabled: string;
    userId: string;
    vip: string;
    order: string;
  }
): void;

function logEvent(name: 'vip_profile_click', payload: {}): void;

function logEvent(
  name: 'vip_portal_click',
  payload: {
    entry: string;
  }
): void;

function logEvent(
  name: 'c2_second_search',
  payload: {
    original_keyword: string;
    keyword: string;
    user_id: string;
    visitor_id: string | undefined;
    platform: string;
  }
): void;

function logEvent(
  name: 'c2_search_filter',
  payload: {
    type: string;
    enabled: string;
    keyword: string;
    resultCount: string;
    userId: string;
    vip: string;
    order: string;
    sorting_step?: string;
    cook_method?: string;
    kitchen_appliances?: string;
    click_trial?: string;
  }
): void;

function logEvent(
  name: 'c3_edit_sections',
  payload: {
    username?: string;
    where: 'introduction' | 'no_authors_pick' | 'no_public_save';
  }
): void;

function logEvent(
  name: 'c3_author_key_metrics',
  payload: {
    username?: string;
    authorId?: string;
    where: 'recipes' | 'dishes' | 'follower' | 'following' | 'campaign';
  }
): void;

function logEvent(
  name: 'c3_profile_key_metrics',
  payload: {
    username?: string;
    authorId?: string;
    where: 'recipes' | 'dishes' | 'follower' | 'following' | 'campaign';
  }
): void;

function logEvent(
  name: 'v3_author',
  payload: {
    visitorId?: string;
    authorId?: string;
    entry_type: 'personal' | 'user_page';
  }
): void;

function logEvent(
  name: 'c3_author_with_more_sections',
  payload: {
    click: 'true' | 'false';
  }
): void;

function logEvent(
  name: 'c3_dashboard',
  payload: {
    username?: string;
    where: 'settings' | 'after_tips';
  }
): void;

function logEvent(
  name: 'login_bottom_btn',
  payload: { platform?: string }
): void;

function logEvent(name: string, payload: {}): void {
  analytics && analytics.logEvent(name, payload);
}

export const logger = { logEvent };
